<template>
  <section id="dashboard-blumar">
    <!--
    <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Carga de archivos"></screenshot>
    -->
    <b-tabs v-model="tabIndex">
      <!-- Tab Carga de Archivos -->

      <b-tab title="Carga de Archivos">
        <carga-archivos-tab-vue></carga-archivos-tab-vue>
      </b-tab>

      <!-- Tab Historico de Carga -->

      <b-tab title="Histórico de Carga">
        <historico-carga-tab-vue></historico-carga-tab-vue>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";



import CargaArchivosTabVue from "./CargaArchivosTab.vue";
import HistoricoCargaTabVue from "./HistoricoCargaTab.vue";

export default {
  data()
  {

    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Carga de Archivos")
    {
      tabIndex = 0
    }


    if (activeTabName == "Histórico de Carga")
    {
      tabIndex = 1
    }


    return {

      tabIndex: tabIndex



    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,

    CargaArchivosTabVue,
    HistoricoCargaTabVue
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {


  },

  methods: {



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

