<template>
  <div>
    <b-row>
      <b-col class="m-estadisticas" xl="12" md="12" lg="12">
        <h3>{{ title }}</h3>
      </b-col>
    </b-row>
    <b-card-group deck>
      <b-card
        v-for="(item, index) in data"
        :key="index"
        :xl="`${size}`"
        :md="`${size}`"
      >
        <b-row no-gutters>
          <b-col class="icon-statistics" cols="2">
            <b-avatar size="48" :variant="item.color">
              <feather-icon size="24" :icon="item.icon" />
            </b-avatar>
          </b-col>
          <b-col>
            <b-card-body :title="item.title">
              <b-card-text class="font-medium-1">
                {{ item.subtitle }}
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {
  BCardGroup,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BCardGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.icon-statistics {
  padding: 5% 0%;
  display: grid;
}
.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
</style>
