<template>
    <b-row class="d-flex justify-content-end">
        <b-col sm xs class="d-md-flex justify-content-end botonPDF">
            <a @click.prevent="downloadScreenshots"
               title="Descargar Screenshot Vista" >
                <feather-icon icon="FileIcon" size="30" />
            </a>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
    components: {
        BRow,
        BCol
    },
    props: {
        namePdf: {
            type: String,
            default: ""
        }
    },
    methods: {
        downloadScreenshots() {
            let name = this.namePdf;
            var body = document.body,
                html = document.documentElement;

            let height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );
            document.body.style.height = `${height}px`;
            window.scrollTo(0, 0);

            html2canvas(document.body, {
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: body.scrollHeight
            }).then(function(canvas) {
                const img = new Image();

                // let imgData = canvas.toDataURL("image/pdf");
                const imgData = canvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                // let doc = new jsPDF("p", "mm", [canvas.width, canvas.height]);
                const doc = new jsPDF("p", "mm", [
                    document.documentElement.offsetWidth,
                    body.scrollHeight
                ]);

                // doc.addImage(
                //     imgData,
                //     "PDF",
                //     10,
                //     10,
                //     canvas.width,
                //     canvas.height
                // );
                // doc.addImage(
                //     imgData,
                //     "PDF",
                //     10,
                //     10,
                //     canvas.width,
                //     canvas.height
                // );

                const imgProps = doc.getImageProperties(imgData);
                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                doc.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
                doc.save(name + "_" + new Date().getTime() + ".pdf");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.botonPDF {
    margin-top: -64px !important;
    display: flex;
}
@media only screen and (max-width: 576px) {
    .botonPDF {
        display: none;
    }
}
</style>
